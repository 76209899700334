import { useParams } from "react-router-dom";

import TradingViewWidget from "react-tradingview-widget";
import './App.css';

function Trading({pair}) {
  const {id}=useParams()
 

 

  return (
    <div className="app">
      {pair==="FTVTUSDT"?<div style={{textAlign:"center"}}>

    <h2>Bir Sorun Oluştu. Lütfen Daha Sonra Tekrar Deneyin.</h2>

      </div>:<><TradingViewWidget
                  symbol={pair}
                  // symbol={"BTCTRY"}
                  hide_top_toolbar={false}
                  hide_side_toolbar={false}
                  withdateranges={false}
                  allow_symbol_change={false}
                 
                  locale="tr"
                  autosize
                  
                /></>}

    </div>
  );
}

export default Trading;
